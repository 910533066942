document.addEventListener('DOMContentLoaded', () => {
        const facades = document.querySelectorAll('.video-facade');
        let currentVideo = null;

        const loadVideo = (facade) => {
            const videoContainer = facade.nextElementSibling;
            const video = videoContainer.querySelector('video');

            if (video.getAttribute('src') !== facade.dataset.videoSrc) {
                video.setAttribute('src', facade.dataset.videoSrc);
                video.load();
            }

            video.addEventListener('canplay', () => {
                facade.style.display = 'none';
                videoContainer.style.display = 'block';
                video.play();
                currentVideo = video;
            }, { once: true });
        };

        facades.forEach(facade => {
            facade.addEventListener('click', () => {
                if (currentVideo) {
                    currentVideo.pause();
                    currentVideo.parentNode.style.display = 'none';
                    currentVideo.parentNode.previousElementSibling.style.display = 'flex';
                }
                
                const videoContainer = facade.nextElementSibling;
                const video = videoContainer.querySelector('video');

                if (video.readyState >= 3) { // Video is already loaded and can play immediately
                    facade.style.display = 'none';
                    videoContainer.style.display = 'block';
                    video.play();
                    currentVideo = video;
                } else {
                    loadVideo(facade);
                }
            });
        });

        // Lazy load videos when they are about to come into view
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const facade = entry.target;
                    const videoContainer = facade.nextElementSibling;
                    const video = videoContainer.querySelector('video');
                    
                    if (!video.getAttribute('src')) {
                        video.setAttribute('src', facade.dataset.videoSrc);
                        video.load();
                    }
                }
            });
        }, {
            threshold: 0.25
        });

        facades.forEach(facade => {
            observer.observe(facade);
        });
    });